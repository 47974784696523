import React, { useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import About from './About'
import HallRentals from './HallRentals'
import Pool from './Pool'
import SwimTeam from './SwimTeam'
import Navbar from './Navbar'
import HamburgerMenu from './HamburgerMenu'
import Footer from './Footer'

function App() {
  const [hamburgerVisible, setHamburgerVisible] = useState()

  return (
    <>
      <Navbar {...{ hamburgerVisible, setHamburgerVisible }} />
      <HamburgerMenu {...{ hamburgerVisible, setHamburgerVisible }} />
      <Switch>
        <Route path="/" exact component={About} />
        <Route path="/hallrentals" exact component={HallRentals} />
        <Route path="/pool" exact component={Pool} />
        <Route path="/swimteam" exact component={SwimTeam} />
        <Route render={() => <Redirect to="/" />} />
      </Switch>
      <Footer />
    </>
  )
}

export default App
