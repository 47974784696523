import React from 'react'
import FacebookLogo from 'Images/facebook-logo'

const Footer = () => {
  return (
    <div className="footer">
      <div className="content">
        <div className="address-section">
          <a href="https://goo.gl/maps/4UCCPpHynL3uAjK38" target="_blank">
            <div>Country Club Estates Recreation Association</div>
            <div>1 Paul Marr Drive Glen Burnie, Maryland</div>
          </a>
        </div>
        <div className="contact-section">
          <div>
            <a href="tel:410-761-5431">(410) 761-5431</a>
            <a href="mailto:contact@ccera.com" target="_blank">
              contact@ccera.com
            </a>
          </div>
          <a
            className="facebook-logo"
            href="https://www.facebook.com/countryclubpoolglenburnie"
            target="_blank"
          >
            <FacebookLogo />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Footer
