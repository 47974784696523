import React from 'react'
import { Link, withRouter } from 'react-router-dom'

const HamburgerMenu = ({ hamburgerVisible, setHamburgerVisible, location }) => {
  const isCurrentPage = section => {
    return location.pathname === section
  }

  const links = [
    { displayName: 'Home', path: '/' },
    { displayName: 'Pool', path: '/pool' },
    { displayName: 'Swim Team', path: '/swimteam' },
    { displayName: 'Hall Rentals', path: '/hallrentals' }
  ]

  return (
    <div id="hamburger-menu" className={hamburgerVisible ? 'open' : ''}>
      {links.map(current => {
        return (
          <Link
            className={isCurrentPage(current.path) ? 'current' : ''}
            to={current.path}
            onClick={() => {
              window.scrollTo(0, 0)
              setHamburgerVisible(false)
            }}
          >
            {current.displayName}
          </Link>
        )
      })}
      <a href="https://www.facebook.com/pg/countryclubpoolglenburnie/events/">Events</a>
    </div>
  )
}

export default withRouter(HamburgerMenu)
