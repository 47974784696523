import React from 'react'
import StackerPlaceholder from 'Images/square.png'

const Pool = () => {
  const poolImages = [StackerPlaceholder, StackerPlaceholder]

  const eventTypeList = [
    'Parties',
    'Birthdays',
    'Business Meetings',
    'Weddings',
    'Bar Mitzvahs',
    'Showers'
  ]

  return (
    <>
      <div className="page-container">
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="page-title">Hall Rentals</span>
            </h1>
          </div>
          <div className="sub-block">
            <div>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
                lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna
                mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur.Praesent
                commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus
                vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis
                ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur.
              </p>
              <div className="feature-list-header">
                <strong>Our Facilities Are Equipped To Accomodate</strong>
              </div>
              <div className="sub-block feature-list no-collapse">
                <ul>
                  {eventTypeList.slice(0, Math.floor(eventTypeList.length / 2)).map(current => {
                    return <li>{current}</li>
                  })}
                </ul>
              </div>
              <div className="sub-block feature-list no-collapse">
                <ul>
                  {eventTypeList.slice(Math.floor(eventTypeList.length / 2)).map(current => {
                    return <li>{current}</li>
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="promo-image-spreader pool-image-spreader">
            {poolImages.map(current => {
              return (
                <div>
                  <div className="image-container">
                    <img src={current} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="sub-section">Additional Information</span>
            </h1>
          </div>
          <div className="sub-block">
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna
              mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur. Praesent
              commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel
              augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare
              vel eu leo. Aenean lacinia bibendum nulla sed consectetur.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pool
