import React from 'react'
import StackerPlaceholder from 'Images/square.png'

const Pool = () => {
  const poolImages = [StackerPlaceholder, StackerPlaceholder]

  const amenitiesList = [
    'Praesent commodo cursus scelerisque nisl consectetur et',
    'Praesent commodo cursus scelerisque nisl consectetur et',
    'Praesent commodo cursus scelerisque nisl consectetur et',
    'Praesent commodo cursus scelerisque nisl consectetur et',
    'Praesent commodo cursus scelerisque nisl consectetur et',
    'Praesent commodo cursus scelerisque nisl consectetur et'
  ]

  const membershipPricing = [
    { group: 'Family', price: '520' },
    { group: 'Couple', price: '430' },
    { group: 'Single', price: '290' },
    { group: 'Pre-Senior', price: '385' },
    { group: 'Senior', price: '330' }
  ]

  return (
    <>
      <div className="page-container">
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="page-title">Pool</span>
            </h1>
          </div>
          <div className="sub-block">
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna
              mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur.Praesent
              commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel
              augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare
              vel eu leo. Aenean lacinia bibendum nulla sed consectetur.
            </p>
          </div>
        </div>
        <div>
          <div className="promo-image-spreader pool-image-spreader">
            {poolImages.map(current => {
              return (
                <div>
                  <div className="image-container">
                    <img src={current} />
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="sub-section">Amenities</span>
            </h1>
          </div>
          <div className="sub-block">
            <div className="feature-list">
              <ul>
                {amenitiesList.map(current => {
                  return <li>{current}</li>
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="sub-section">Membership Rates</span>
            </h1>
          </div>
          <div className="sub-block">
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna
              mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur. Praesent
              commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel
              augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare
              vel eu leo. Aenean lacinia bibendum nulla sed consectetur.
            </p>
            <div className="price-list no-collapse">
              {membershipPricing.map(current => {
                return (
                  <div className="sub-block">
                    <h3>{current.group}</h3>
                    <p>${current.price} per year</p>
                  </div>
                )
              })}
              <div className="sub-block no-collapse">
                <h3>Guest Pass</h3>
                <p>$5 or an hour of service</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Pool
