import React from 'react'

const SwimTeam = () => {
  return (
    <>
      <div className="page-container">
        <div className="promo-information-block">
          <div className="sub-block">
            <h1>
              <span className="page-title">Swim Team</span>
            </h1>
          </div>
          <div className="sub-block promo-video-player">
            <div>
              <iframe
                title="swim-team-video"
                width="560"
                height="315"
                src="https://www.youtube.com/embed/Era0VAIUATw"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>

        <div className="promo-text-block swim-team">
          <p>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis
            ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue
            laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu
            leo. Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum
            faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu leo. Aenean
            lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque
            nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Nullam quis risus eget urna mollis ornare vel eu leo. Aenean lacinia bibendum
            nulla sed consectetur.
          </p>
        </div>
      </div>
    </>
  )
}

export default SwimTeam
