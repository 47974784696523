import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

const Navbar = ({ hamburgerVisible, setHamburgerVisible, location }) => {
  const links = [
    { displayName: 'Pool', url: '/pool' },
    { displayName: 'Swim Team', url: '/swimteam' },
    { displayName: 'Hall Rentals', url: '/hallrentals' }
  ]

  const isCurrentPage = section => {
    return location.pathname === section ? 'active' : ''
  }

  return (
    <div id="navbar" className={location.pathname === '/' ? 'transparent' : ''}>
      <div>
        <div id="company-name">
          <Link to="/">CCERA</Link>
        </div>
        <div id="navbar-links">
          <ul id="navbar-link-list">
            {links.map(current => {
              return (
                <li
                  key={`navbar-link-${current.displayName}`}
                  className={isCurrentPage(current.url)}
                >
                  <Link to={current.url}>{current.displayName}</Link>
                </li>
              )
            })}
            <li>
              <a
                href="https://www.facebook.com/pg/countryclubpoolglenburnie/events/"
                target="_blank"
              >
                Events
              </a>
            </li>
          </ul>
        </div>
        <div
          id="hamburger"
          className={hamburgerVisible ? 'active' : ''}
          onClick={() => setHamburgerVisible(!hamburgerVisible)}
        />
      </div>
    </div>
  )
}

export default withRouter(Navbar)
