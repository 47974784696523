import React from 'react'
import { Link } from 'react-router-dom'
import AboutImage from 'Images/about.gif'
import StackerPlaceholder from 'Images/square.png'
import FacebookLogo from 'Images/facebook-logo'

const About = () => {
  const stackerContent = [
    { title: 'Swimming Pool', image: StackerPlaceholder, link: '/pool' },
    { title: 'Swim Team', image: StackerPlaceholder, link: '/swimteam' },
    { title: 'Hall Rentals', image: StackerPlaceholder, link: '/hallrentals' }
  ]

  // TODO IMPLEMENT REAL FEED IMAGES
  const feedImages = [
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' },
    { src: StackerPlaceholder, url: 'https://facebook.com/test' }
  ]

  // TODO CAN I GET RID OF ABOUT TAGLINE EXTRA DIV???

  return (
    <>
      <img className="header-image" src={AboutImage} />
      <div className="page-container about">
        <div>
          <div className="promo-text-block about">
            <div className="about-tagline">Ran By The Community, For The Community</div>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
              lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna
              mollis ornare vel eu leo. Aenean lacinia bibendum nulla sed consectetur. Praesent
              commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel
              augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare
              vel eu leo. Aenean lacinia bibendum nulla sed consectetur.
            </p>
          </div>
        </div>
        <div>
          <div className="promo-image-spreader about-image-spreader">
            {stackerContent.map(current => {
              return (
                <div>
                  <Link
                    className="image-container"
                    to={current.link}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    <img src={current.image} />
                    <div className="overlay">{current.title}</div>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <div>
          <a
            className="promo-feed-title"
            href="https://www.facebook.com/countryclubpoolglenburnie/"
          >
            <FacebookLogo className="facebook-logo" />
            <div>What's Going On</div>
          </a>
          <div className="promo-feed-images">
            {feedImages.map(current => {
              return (
                <div style={{ backgroundImage: `url(${current.src})` }}>
                  <div />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

export default About
